<template>
  <div class="user-needs-list">
    <van-pull-refresh v-model="pageData.refreshing" @refresh="loadData(true)">
      <van-list v-model="pageData.loading" @load="loadData(false)" :finished="!pageData.hasMore"
                :immediate-check="true">
        <needs-card :item="item" v-for="item in pageData.records"/>
      </van-list>
    </van-pull-refresh>
    <div style="text-align: center;margin: 16px 0;color: #999" v-if="pageData.total>0&&!pageData.hasMore">
      灵感到底了~
    </div>
    <div v-if="!pageData.records || !pageData.records.length" class="no-data">
      <img data-v-4fcf8a44=""
           src="https://cdn.001ppt.cn/h5/assets/images/noData.png"
           width="150">
      <span data-v-4fcf8a44="">噢！这可能有点儿空 (⊙v⊙)</span>
    </div>
  </div>
</template>


------------------ script ------------------
<script>
import {needsV2Api} from "../../api/v2/needsV2Api";
import NeedsCard from "./NeedsCard";
import {bizResourceV2Api} from "../../api/v2/bizResourceV2Api";

export default {
  components: {NeedsCard},
  props: {
    userId: {
      type: String
    },
  },


  data() {
    return {
      pageData: {
        total: 0,
        current: 0,
        size: 3,
        hasMore: true,
        records: [],
        loading: false,
        refreshing: false
      }
    }
  },


  methods: {
    async loadData(isRefresh) {
      try {
        this.pageData.refreshing = isRefresh;
        this.pageData.loading = !isRefresh;
        const current = isRefresh ? 1 : this.pageData.current + 1;
        const size = this.pageData.size;
        const userId = this.userId;
        const res = await bizResourceV2Api.search({current, size, userId})
        res.size = res.pageSize || res.size;
        res.hasMore = res.total > res.current * res.size;
        this.pageData.total = res.total;
        this.pageData.current = res.current;
        this.pageData.size = res.size;
        this.pageData.hasMore = res.hasMore;

        if (isRefresh) {
          this.pageData.records = res.records;
        } else {
          this.pageData.records = [...this.pageData.records, ...res.records];
        }

      } finally {
        this.pageData.refreshing = false;
        this.pageData.loading = false;
      }
    }
  }

}
</script>


------------------ styles ------------------
<style lang="less" scoped>
.no-data {
  height: calc(100vh - 105px - 160px - 88px - 118px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    margin-top: 10px;
    color: #999;
    font-size: 12px;
    opacity: .25;
  }
}

</style>
