<template>
  <page class="mine">
    <v-header></v-header>
    <div class="container-group" @scroll="onScroll">
      <template v-if="user.titleResourceUrl">
        <img :src="user.titleResourceUrl" width="100%" class="user-title-img">
      </template>
      <img v-else src="https://cdn.001ppt.cn/h5/assets/images/userinfo.png" width="100%" class="user-title-img">

      <div class="userinfo-group">
        <div class="user-card">
          <div class="avatar-cell">
            <avatar class="avatar" :src="user.photoResourceUrl" :vipIdentify="user.vipIdentify"
                    :vipRightId="user.vipRightId"></avatar>
          </div>
          <div class="usernamegroup">
            <div>
              <div class="user-group">
                <span class="username">{{ user.username }}</span>
                <user-level style="margin-left: 8px;transform: translateY(1px)" :level="user.userLevel"/>
                <!--                <img class="level" :src="`https://cdn.001ppt.cn/h5/static/level/level${user.userLevel}.svg`">-->
              </div>
              <div class="desc">
                <span>{{ clipCity(user.city) }}</span>
                <span class="line">|</span>
                <span>{{ user.profession || '无' }}</span>
              </div>
              <div class="rank" v-if="user.lastCreatorRankIssue" @click.stop="goRank">
                <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/user-rank-top-icon.svg" alt="">
                入选
                <template>
                  <template v-if="user.lastCreatorRankType===1">上传榜</template>
                  <template v-else-if="user.lastCreatorRankType===2">浏览榜</template>
                  <template v-else-if="user.lastCreatorRankType===3">推荐榜</template>
                  <template v-else-if="user.lastCreatorRankType===4">收藏榜</template>
                  <template v-else-if="user.lastCreatorRankType===5">人气榜</template>
                </template>
                TOP{{ user.lastCreatorRankIndex }}
                <van-icon name="arrow" style="transform: translateY(2px)"/>
              </div>
            </div>

            <!-- <i class="iconfont icon-share" @click="showShare = true"></i>-->

          </div>
        </div>

        <div class="box-group"></div>
        <div class="user-count">
          <div>
            <span class="number">{{ numWanFilter(user.popularity) }} <img
              style="height: 16px;width: 16px;transform: translateY(-1px)" src="https://cdn.001ppt.cn/h5/img/fire.svg"
              alt=""></span>
            <span class="title">人气</span>
          </div>
          <div @click="showFans(1)">
            <span class="number">{{ numWanFilter(user.fansAmount) }}</span>
            <span class="title">粉丝</span>
          </div>
          <div @click="showFans(2)">
            <span class="number">{{ numWanFilter(user.focusAmount) }}</span>
            <span class="title">关注</span>
          </div>
        </div>
        <div class="focus-group">
          <template v-if="!ifFocus">
            <a href="javascript: void (0)" class="focus-btn btn" @click="toFocus">
              <span>+ 关注</span>
            </a>
          </template>
          <template v-else>
            <a href="javascript: void (0)" class="focus-btn is-focus btn" @click="cancelFocus">
              <span>已关注</span>
            </a>
          </template>


        </div>

        <van-tabs v-if="info" v-model="active" background="#fff" animated swipeable>
          <van-tab>
            <template #title>
              <span class="tab-title">TA的上传</span>
            </template>
            <div :class="upload.records.length && 'tab-body'" v-if="upload.records.length">
              <van-pull-refresh v-model="upload.refreshing" @refresh="loadData(upload,true)">
                <van-list v-model="upload.loading" @load="loadData(upload,false)" :finished="!upload.hasMore"
                          :immediate-check="false">
                  <div v-for="(item) in upload.records" style="margin-bottom: 16px">
                    <plan-card :detail="item" @handle-click="clickItem"/>
                  </div>
                  <div class="bottom-text" v-if="!upload.hasMore">
                    <div v-if="upload.records && upload.records.length">灵感到底了~</div>
                    <div v-else
                         class="no-data-box">
                      <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150"
                           style="position: relative;top: -100px;">
                      <span class="no-data-text">噢！这可能有点儿空 (⊙v⊙)</span>
                    </div>
                  </div>
                  <div class="bottom-text" v-else-if="upload.loading">加载中</div>
                  <div class="bottom-text" v-else>加载更多</div>
                </van-list>
              </van-pull-refresh>
            </div>
            <div class="no-data" v-else>
              <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150">
              <span>噢！这可能有点儿空 (⊙v⊙)</span>
            </div>
          </van-tab>
<!--          <van-tab>-->
<!--            <template #title>-->
<!--              <span class="tab-title">TA的资源</span>-->
<!--            </template>-->
<!--            <div class="like-title">-->
<!--              <span @click="publishActive=1" :class="publishActive===1&&'active'">TA的资源</span>-->
<!--              <span @click="publishActive=2" :class="publishActive===2&&'active'">TA的需求</span>-->
<!--            </div>-->
<!--            <user-needs-list v-show="publishActive===2" :userId="user.id"/>-->
<!--            <user-resource-list v-show="publishActive===1" :userId="user.id"/>-->
<!--          </van-tab>-->
          <van-tab>
            <template #title>
              <span class="tab-title">TA的推荐</span>
            </template>

            <div class="like-title">
              <span @click="caseActive = 1" :class="caseActive === 1 && 'active'">推荐的方案</span>
              <span @click="caseActive = 2" :class="caseActive === 2 && 'active'">推荐的合集</span>
            </div>

            <template v-if="caseActive == 1">
              <div :class="recommend.records.length && 'tab-body'" style="padding-top: 15px;"
                   v-if="recommend.records.length">
                <van-pull-refresh v-model="recommend.refreshing" @refresh="loadData(recommend,true)">
                  <van-list v-model="recommend.loading" @load="loadData(recommend,false)" :finished="!recommend.hasMore"
                            :immediate-check="false">
                    <div v-for="(item) in recommend.records">
                      <plan-card style="margin-bottom: 16px" :detail="item" @handle-click="clickItem"></plan-card>
                    </div>
                    <div class="bottom-text" v-if="!recommend.hasMore">
                      <div v-if="recommend.records && recommend.records.length">灵感到底了~</div>
                      <div v-else
                           class="no-data-box">
                        <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150"
                             style="position: relative;top: -100px;">
                        <span class="no-data-text">噢！这可能有点儿空 (⊙v⊙)</span>
                      </div>
                    </div>
                    <div class="bottom-text" v-else-if="recommend.loading">加载中</div>
                    <div class="bottom-text" v-else>加载更多</div>
                  </van-list>
                </van-pull-refresh>
              </div>
              <div class="no-data-case" v-else>
                <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150">
                <span>噢！这可能有点儿空 (⊙v⊙)</span>
              </div>
            </template>

            <template v-else>
              <div :class="recommendPlanList.records.length && 'tab-body'" style="padding-top: 15px;"
                   v-if="recommendPlanList.records.length">
                <van-pull-refresh v-model="recommendPlanList.refreshing" @refresh="loadData(recommendPlanList,true)">
                  <van-list v-model="recommendPlanList.loading" @load="loadData(recommendPlanList,false)"
                            :finished="!recommendPlanList.hasMore"
                            :immediate-check="false">
                    <div v-for="(item) in recommendPlanList.records">
                      <v-case :detail="item" group @handle-click="clickItem1"></v-case>
                    </div>
                    <div class="bottom-text" v-if="!recommendPlanList.hasMore">
                      <div v-if="recommendPlanList.records && recommendPlanList.records.length">灵感到底了~</div>
                      <div v-else
                           class="no-data-box">
                        <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150"
                             style="position: relative;top: -100px;">
                        <span class="no-data-text">噢！这可能有点儿空 (⊙v⊙)</span>
                      </div>
                    </div>
                    <div class="bottom-text" v-else-if="recommendPlanList.loading">加载中</div>
                    <div class="bottom-text" v-else>加载更多</div>
                  </van-list>
                </van-pull-refresh>
              </div>
              <div class="no-data-case" v-else>
                <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150">
                <span>噢！这可能有点儿空 (⊙v⊙)</span>
              </div>
            </template>

          </van-tab>
          <van-tab>
            <template #title>
              <span class="tab-title">TA的资料</span>
            </template>
            <div class="tabs-body base">
              <div class="key-value">
                <span class="key">用户昵称</span>
                <span class="value">{{ user.username }}</span>
              </div>
              <div class="key-value">
                <span class="key">性<span style="color: #fff">性别</span>别</span>
                <span class="value">{{ setVal(user.sex) }}</span>
              </div>
              <div class="key-value">
                <span class="key">所在城市</span>
                <span class="value">{{ clipCity(user.city) }}</span>
              </div>
              <div class="key-value">
                <span class="key">职<span style="color: #fff">职业</span>业</span>
                <span class="value">{{ setVal(user.profession) }}</span>
              </div>
              <div class="key-value">
                <span class="key">简<span style="color: #fff">简介</span>介</span>
                <span class="value" style="line-height: 22px;">{{ setVal(user.introduction) }}</span>
              </div>
              <div class="key-value">
                <span class="key">个人标签</span>
                <span class="value" style="display: flex;align-items: center;flex-wrap: wrap;">
                   <template v-if="user.tags && user.tags.length">
                     <template v-for="(i, index) in user.tags">
                        <div style="padding: 4px 0;">
                          <span class="tag" style="width:auto;padding: 0 15px;"
                                :style="{marginRight: index<user.tags.length - 1?'10px': 0}">{{ i }}</span>
                        </div>
                       </template>
                    </template>
                    <span v-else class="value">未知</span>
                </span>
              </div>
            </div>
          </van-tab>
        </van-tabs>

        <van-tabs v-else v-model="fansActive" background="#fff" animated @click="onClick" swipeable>
          <van-tab>
            <template #title>
              <span class="tab-title">TA的主页</span>
            </template>
          </van-tab>
          <van-tab>
            <template #title>
              <span class="tab-title">TA的粉丝</span>
            </template>
            <div class="base" v-if="fans.length">
              <template v-for="item in fans">
                <v-cell :detail="item" v-if="item.focusUserId" btn="已关注" @handle-click="cancelFocusUser"></v-cell>
                <v-cell :detail="item" v-else btn="关注" type="default" @handle-click="focusUser"></v-cell>
              </template>
              <span class="more" v-if="fans.length >= 5" @click="showMore(0)">查看更多</span>
            </div>
            <div class="no-data" v-else>
              <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150">
            </div>
          </van-tab>
          <van-tab>
            <template #title>
              <span class="tab-title">TA的关注</span>
            </template>


            <div class="base" v-if="focus.length">
              <template v-for="item in focus">
                <v-cell :detail="item" v-if="item.focusUserId" btn="已关注" @handle-click="cancelFocusUser"></v-cell>
                <v-cell :detail="item" v-else btn="关注" type="default" @handle-click="focusUser"></v-cell>

              </template>
              <span class="more" v-if="focus.length >= 5" @click="showMore(1)">查看更多</span>
            </div>
            <div class="no-data" v-else>
              <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150">
            </div>

          </van-tab>
        </van-tabs>

      </div>
    </div>


  </page>

</template>

<script>
import vCase from '@/components/case'
import VCell from '@/components/cell'
import avatar from '@/components/avatar'
import {user} from '@/api'
import {mapGetters} from 'vuex'
import {planV2Api} from "../../api/v2/planV2Api";
import {querySelector, sleep} from "../../config/util";
import UserLevel from "../../components/userLevel";
import UserNeedsList from "../../components/biz/userNeedsList";
import UserResourceList from "../../components/biz/userResourceList";
import {nav} from "@/config/nav";
import PlanCard from "../../components/planCard";
import {planRecommendV2Api} from "../../api/v2/planRecommendV2Api";

export default {
  components: {PlanCard, UserResourceList, UserNeedsList, UserLevel, vCase, avatar, VCell},
  computed: {...mapGetters(['userInfo'])},
  data() {
    return {
      caseActive: 1,
      publishActive: 1,
      showShare: false,
      active: 0,
      info: true,
      fansActive: 1,
      user: {},
      uploadList: [],
      recommendList: [],
      myRecommendPlanList: [],
      fans: [],
      focus: [],
      ifFocus: false,
      recommend: {
        name: 'recommend',
        current: 0,
        pageSize: 20,
        records: [],
        refreshing: false,
        loading: false,
        hasMore: true,
        api: this.myRecommendApi
      },
      upload: {
        name: 'upload',
        current: 0,
        pageSize: 20,
        records: [],
        refreshing: false,
        loading: false,
        hasMore: true,
        api: this.uploadApi
      },
      recommendPlanList: {
        name: 'planList',
        current: 0,
        pageSize: 20,
        records: [],
        refreshing: false,
        loading: false,
        hasMore: true,
        api: this.myRecommendListApi
      },
    }
  },
  // created() {
  //   this.getUserInfo();
  //   this.myFocus();
  //   this.myFans();
  //   this.loadData(this.recommend, true);
  //   this.loadData(this.upload, true);
  //   this.loadData(this.recommendPlanList, true);
  // },

  watch: {

    fansActive(val) {
      if (val === 0) {
        this.info = true;
        this.active = 0;
      }
    }

  },

  async activated() {
    if (this.user.id !== this.$route.params.id) {
      this.getUserInfo().then();
      this.myFocus().then();
      this.myFans().then();
      this.loadData(this.recommend, true).then();
      this.loadData(this.upload, true).then();
      this.loadData(this.recommendPlanList, true).then();
    } else {
      // document.getElementsByClassName(`container-group`)[0].scrollTop = this.$store.state.scroll;
      const containerGroup  = await querySelector('.container-group')
      containerGroup.scrollTop = this.$store.state.scrollPosition.creatorPage || 0
    }
  },


  methods: {


    onScroll(e){
      this.$store.state.scrollPosition.creatorPage = e.target.scrollTop
    },

    // 前往rank页
    goRank() {
      const creatorTabMap = {
        1: 'upload',
        2: 'beBrowse',
        3: 'beRecommend',
        4: 'beCollect',
        5: 'popularity'
      }
      this.$router.push({
        path: '/rank',
        query: {
          nav: 'creator',
          issue: this.user.lastCreatorRankIssue,
          creatorTab: creatorTabMap[this.user.lastCreatorRankType]
        }
      })
    },

    // 加载数据
    async loadData(model, isRefresh) {
      let current = 1;
      let pageSize = 20;
      if (isRefresh) {
        model.refreshing = true;
      } else {
        model.loading = true;
        current = model.current + 1;
      }
      model.current = current;

      try {
        let res = await model.api({current, pageSize, size: pageSize});
        res = res.data;
        model.current = res.current;
        model.pageSize = res.pageSize || res.size;
        model.hasMore = res.total > model.pageSize * model.current;
        // 处理records
        let records = isRefresh ? [] : model.records;
        for (const row of res.records) {
          records.push(row)
        }
        model.records = records;
      } catch (e) {
        model.current--;
        console.log(model);
        console.error(e);
      } finally {
        model.loading = false;
        await sleep(300);
        model.refreshing = false;
      }

    },

    async getIfFocus() {
      const {code, data} = await user.ifFocus({userId: this.userInfo.id, otherUserId: this.user.id});

      this.ifFocus = data;

    },
    async getUserInfo() {
      if (Object.keys(this.userInfo).length) {
        if (this.$route.params.id == this.userInfo.id) {
          this.$router.replace('/mine');
          return;
        }
      }

      const {data, code} = await user.userInfo(this.$route.params.id);
      data.tags = data.tags && data.tags.length ? data.tags.split(',') : [];
      this.user = data;

      if (Object.keys(this.userInfo).length) {
        this.getIfFocus();
      }

    },
    showFans(active) {
      this.info = false;
      this.fansActive = active;
    },


    async uploadApi({current, size}) {
      const res = await planV2Api.findPublishByUserId({current, size, userId: this.$route.params.id});
      return {data: res}
    },

    //我的推荐
    async myRecommendApi() {
      const res = await planRecommendV2Api.findByUserId({current: 1, size: 999, userId: this.$route.params.id,pageSize: 999})
      return {data: res}
    },
    //我的推荐方案合集
    async myRecommendListApi() {
      return await user.myRecommendList({current: 1, size: 999, userId: this.$route.params.id});
    },

    //我的关注
    async myFocus() {
      const res = await user.myFocus({current: 1, size: 5, userId: this.$route.params.id});
      this.focus = res.data.records;
    },
    //我的粉丝
    async myFans() {
      const res = await user.myFans({current: 1, size: 5, userId: this.$route.params.id});
      this.fans = res.data.records;
    },

    onClick(index) {
      if (index === 0) {
        this.info = true;
      }
    },


    //关注
    async toFocus() {
      this.$loading();
      try {
        const {code} = await user.focus({toUserId: this.$route.params.id, userId: this.userInfo.id});
        if (code != 1) return;
        this.$toast.success('关注成功');
        this.myFans();
        this.user.fansAmount += 1;
        this.ifFocus = true;
      } finally {
        this.$loading.close();
      }

    },

    async cancelFocus() {
      this.$loading();
      try {
        const {code} = await user.cancelFocus(this.$route.params.id);
        if (code != 1) return;
        this.$toast.success('取消关注成功');
        this.myFans();
        this.user.fansAmount -= 1;
        this.ifFocus = false;
      } finally {
        this.$loading.close();
      }
    },


    clickItem(data) {
      this.$store.commit('SET_scroll', document.getElementsByClassName('container-group')[0].scrollTop)
      nav.goPlanDetail(data.plan.id)
    },

    clickItem1(data) {
      this.$store.commit('SET_scroll', document.getElementsByClassName('container-group')[0].scrollTop)
      this.$router.push(`/casePlanDetail/${data.planListId}`)
    },


    showMore(active) {
      this.$router.push('/fansAndFocus?active=' + active + '&userId=' + this.$route.params.id);
    },


    //关注他的粉丝或者他的关注
    async focusUser(detail) {
      // console.log(detail.id);
      this.$loading();
      try {
        // console.log('关注');
        const {code} = await user.focus({toUserId: detail.id, userId: this.userInfo.id});
        if (code != 1) return;
        this.$toast.success('关注成功');
        this.myFans();
        this.myFocus();
      } catch (e) {
        // console.log('操作失败');
      } finally {
        this.$loading.close();
      }
    },


    //取消关注他的粉丝or关注
    async cancelFocusUser(detail) {
      this.$loading();
      try {
        const {code} = await user.cancelFocus(detail.id);
        if (code != 1) return;
        this.$toast.success('取消关注成功');
        this.myFans();
        this.myFocus();
      } finally {
        this.$loading.close();
      }
    }
  }
}
</script>


<style scoped lang="less">
.mine {
  background: @page-color;

  .container-group {
    height: calc(100vh - var(--header-height));
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: var(--header-height);;
  }

  .user-title-img {
    height: 160px;
    position: relative;
    left: -1px;
    width: calc(100vw + 1px);
    object-fit: cover;
  }

  .userinfo-group {
    height: calc(100vh - 160px - var(--header-height));
    position: relative;

    .user-card {
      background: #fff;
      position: absolute;
      width: calc(100vw - 50px);
      left: 25px;
      height: 90px;
      top: -45px;
      box-shadow: 0 4px 4px 0 rgba(21, 29, 54, 0.05);
      border-radius: @card-radius;
      background: #fff;
      z-index: 1;
      display: flex;
      align-items: center;
      padding-left: 14px;
      padding-right: 50px;
      box-sizing: border-box;

      .avatar-cell {
        width: 52px;
        height: 52px;
        position: relative;

        .avatar {
          width: 52px;
          height: 52px;
          border-radius: 100%;
        }

        .vip-img {
          position: absolute;
          bottom: 2px;
          right: 2px;
          width: 15px;
          height: 15px;
          border-radius: 100%;
        }
      }

      .usernamegroup {
        box-sizing: border-box;
        padding-left: 15px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .user-group {
          display: flex;
          align-items: center;

          .username {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 20px;
            font-weight: bold;
          }

          .level {
            width: 34px;
            height: 17px;
            margin-left: 10px;
          }
        }

        .icon-share {
          font-size: 18px;
          color: var(--text-color);
        }
      }

      &:active {
        background: @page-color;
      }
    }

    .box-group {
      background: #fff;
      height: 45px;
    }

    .user-count {
      background: #fff;
      display: flex;
      padding: 24px 0;

      > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .number {
          font-size: 18px;
          font-weight: bold;
          display: flex;
          align-items: center;
        }

        .title {
          font-size: 12px;
          color: #999;
          margin-top: 3px;
        }
      }
    }

    .focus-group {
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 50px 24px 50px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 140px;
        border: solid 2px var(--text-color);
        border-radius: 21px;

        span {
          font-size: 16px;
          font-weight: bold;
        }

        &.is-focus {
          background: var(--text-color);
          border: solid 2px transparent;

          span {
            color: #fff;
          }
        }
      }

    }

    .tab-title {
      font-weight: bold;
    }

    .like-title {
      display: flex;
      align-items: center;
      padding: 15px 73px 0;

      span {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: var(--grey-color);

        &.active {
          color: var(--text-color);
        }
      }
    }

    .tabs-body {
      box-sizing: border-box;
      background: var(--page-color);
      padding: 24px 28px;
    }

    .base {
      background: #fff;
      border-top: 14px solid var(--page-color);

      .key-value {
        display: flex;
        align-items: center;
        line-height: 40px;

        .key {
          display: inline-block;
          text-align: justify;
          width: 60px;
          color: var(--grey-color);
          opacity: .3;
          text-align-last: justify;
          margin-right: 30px;
          font-weight: bold;
          text-justify: inter-ideograph;
        }

        .value {
          flex: 1;
          display: flex;

          .tag {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
            height: 25px;
            opacity: 0.3;
            color: rgba(80, 80, 80, 1);
            border: 1px solid #999;
            box-shadow: var(--text-color) solid 1px;
            border-radius: 13px;
          }
        }
      }
    }
  }

  .desc {
    display: flex;
    align-items: center;

    span {
      font-size: 12px;
      color: #999;
    }

    .line {
      margin: 0 8px;
    }
  }

}

.more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  color: var(--grey-color);
  cursor: pointer;
}


.no-data {
  height: calc(100vh - 105px - 160px - 88px - 68px);
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  span {
    margin-top: 10px;
    color: #999;
    font-size: 12px;
    opacity: .25;
  }

  .route {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    width: 140px;
    height: 40px;
    color: var(--text-color);
    font-size: 14px;
    border-radius: 21px;
  }
}

.no-data-case {
  background: var(--page-color);
  height: calc(100vh - 155px - 160px - 88px - 68px - 34px);
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  span {
    margin-top: 10px;
    color: #999;
    font-size: 12px;
    opacity: .25;
  }

  .route {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    width: 140px;
    height: 40px;
    color: var(--text-color);
    font-size: 14px;
    border-radius: 21px;
  }
}


.tab-body {
  box-sizing: border-box;
  background: var(--page-color);
  padding: 24px 28px;
}


.no-data-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative
}

.no-data-box .no-data-text {
  position: relative;
  top: -100px;
  margin-top: 10px;
  color: #999;
  font-size: 12px;
  opacity: .25;
}

.bottom-text {
  color: #999;
  text-align: center;
  padding: 16px 0;
}


.no-data-box {
  margin: 200px 0;
}

.rank {
  font-size: 12px;
  background: #f8edd5;
  color: #f5c164;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
  border-top-right-radius: 100px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  margin-top: 4px;

  img {
    height: 12px;
    width: 12px;
    margin-right: 4px;
  }
}


</style>
